/* eslint-disable react/prop-types */
import 'styles/scss/main.scss';
import * as Sentry from '@sentry/react';
// import { initSentry } from 'services/sentry';

// initSentry();

function MyApp({ Component, pageProps, router, err }) {
    return <Component {...pageProps} route={router.route} err={err} />;
}

// export default MyApp;
export default Sentry.withProfiler(MyApp);
